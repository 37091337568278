var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _c("div", { staticClass: "login__func" }, [
      _c("div", { staticClass: "login__func--infoCard" }, [
        _c("div", { staticClass: "flex align-center justify-center" }, [
          _c("label", [_vm._v("帳號：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.userInfo.account,
                expression: "userInfo.account",
                modifiers: { trim: true }
              }
            ],
            attrs: { type: "text", placeholder: "請輸入帳號" },
            domProps: { value: _vm.userInfo.account },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "account", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c("div", { staticClass: "flex align-center justify-center" }, [
          _c("label", [_vm._v("密碼：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.userInfo.password,
                expression: "userInfo.password",
                modifiers: { trim: true }
              }
            ],
            attrs: { type: "password", placeholder: "請輸入密碼" },
            domProps: { value: _vm.userInfo.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "password", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.login()
              }
            }
          },
          [_vm._v("登入")]
        )
      ])
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login__header" }, [
      _c("div", { staticClass: "login__header--title" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/logo.png"),
            alt: "logo",
            width: "180px"
          }
        }),
        _c("strong", [_vm._v("警示資訊管理系統")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("p", [_vm._v("Copyright © 2021 minds Corporation.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }